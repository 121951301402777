import React, { useEffect, useState, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { fetchWishlist, moveToCart, removeFromWishlist, fetchCart } from '../../api/axios'; // Ensure fetchCart is available in axios.js

function Wishlist({ wishlist, setWishlist, cart, setCart, userId }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to load the wishlist
    const loadWishlist = useCallback(async () => {
        try {
            setLoading(true);
            const wishlistData = await fetchWishlist(userId);

            // Filter out any null or undefined items
            const validWishlistItems = wishlistData.products.filter(product => product !== null && product !== undefined);
            setWishlist(validWishlistItems);
            setError(null);
        } catch (error) {
            console.error("Error loading wishlist:", error);
            setError("Failed to load wishlist. Please try again.");
        } finally {
            setLoading(false);
        }
    }, [userId, setWishlist]);

    // Function to load the cart
    const loadCart = useCallback(async () => {
        try {
            const cartData = await fetchCart(userId); // Ensure fetchCart is defined in axios.js
            setCart(cartData.items || []);
        } catch (error) {
            console.error("Error loading cart:", error);
        }
    }, [userId, setCart]);

    useEffect(() => {
        if (userId) {
            loadWishlist();
            loadCart();
        }
    }, [userId, loadWishlist, loadCart]);

    const handleMoveToCart = async (product) => {
        if (!product || !product._id) return; // Ensure product and ID exist
        try {
            setLoading(true);

            // Optimistically update the wishlist state by filtering out the item
            setWishlist((prevWishlist) => prevWishlist.filter((item) => item._id !== product._id));

            // Use the imported moveToCart function to move the item
            await moveToCart(userId, product._id);

            // Re-fetch both wishlist and cart to reflect the latest data
            await loadWishlist();
            await loadCart();

            setError(null);
        } catch (error) {
            console.error('Error moving product to cart:', error);
            setError('Failed to move product to cart');

            // Optionally revert the optimistic update in case of an error
            await loadWishlist();
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveFromWishlist = async (product) => {
        if (!product || !product._id) return; // Ensure product and ID exist
        try {
            await removeFromWishlist(userId, product._id);
            setWishlist(wishlist.filter(item => item._id !== product._id));
            setError(null);
        } catch (error) {
            console.error("Error removing item from wishlist:", error);
            setError("Failed to remove item from wishlist. Please try again.");
        }
    };

    if (loading) {
        return <div>Loading your wishlist...</div>;
    }

    return (
        <div className="wishlist-container">
            <h2>Your Wishlist</h2>

            {error && (
                <div className="wishlist-error-message">
                    {error}
                </div>
            )}

            {wishlist.length === 0 ? (
                <div>Your wishlist is empty.</div>
            ) : (
                <div className="wishlist-items">
                    {wishlist.map(product => (
                        product && product._id && ( // Ensure product and ID are defined
                            <div key={product._id} className="wishlist-item">
                                <div className="wishlist-item-image">
                                    <img src={product.image} alt={product.name} />
                                </div>
                                <div className="wishlist-item-details">
                                    <h3>{product.name}</h3>
                                    <p>Price: ${product.price}</p>
                                </div>
                                <div className="wishlist-item-actions">
                                    <Button
                                        variant="secondary"
                                        onClick={() => handleMoveToCart(product)}
                                    >
                                        Move to Cart
                                    </Button>
                                    <Button
                                        variant="danger"
                                        onClick={() => handleRemoveFromWishlist(product)}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            )}
        </div>
    );
}

export default Wishlist;
