import axios from 'axios';

const api = axios.create({
    baseURL: `${window.location.protocol}//${window.location.hostname}:9000/api`, // Dynamic base URL
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

// Add token to every request if available
api.interceptors.request.use(
    (config) => {
        console.log('API Request to:', config.baseURL + config.url); // Debugging request URL
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

// Handle response errors and redirect on 401
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            // Clear authentication
            localStorage.removeItem('token');
            localStorage.removeItem('user');

            // Redirect to login
            window.location.href = '/login';
            return Promise.reject(error);
        }

        // Handle network errors
        if (!error.response) {
            console.error('Network error:', error);
            return Promise.reject(new Error('Network error occurred'));
        }

        return Promise.reject(error);
    }
);

// API methods

// Fetch all products
export const fetchProducts = async () => {
    try {
        const response = await api.get('/products');
        return response.data;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

// Fetch user's cart
export const fetchCart = async (userId) => {
    try {
        const response = await api.get(`/cart/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching cart:', error);
        throw error;
    }
};

// Fetch user's wishlist
export const fetchWishlist = async (userId) => {
    try {
        const response = await api.get(`/wishlist/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching wishlist:', error);
        throw error;
    }
};

// Add item to cart
export const addToCart = async (userId, productId, quantity = 1) => {
    try {
        const response = await api.post(`/cart/${userId}/items`, { productId, quantity });
        return response.data;
    } catch (error) {
        console.error('Error adding to cart:', error);
        throw error;
    }
};

// Add product to wishlist
export const addToWishlist = async (userId, productId) => {
    try {
        const response = await api.post(`/wishlist/${userId}/products`, { productId });
        return response.data;
    } catch (error) {
        console.error('Error adding to wishlist:', error);
        throw error;
    }
};

// Remove from cart
export const removeFromCart = async (userId, productId) => {
    try {
        const response = await api.delete(`/cart/${userId}/items/${productId}`);
        return response.data;
    } catch (error) {
        console.error('Error removing from cart:', error);
        throw error;
    }
};

// Remove product from wishlist
export const removeFromWishlist = async (userId, productId) => {
    try {
        const response = await api.delete(`/wishlist/${userId}/products/${productId}`);
        return response.data;
    } catch (error) {
        console.error('Error removing item from wishlist:', error);
        throw error;
    }
};

// Move to wishlist
export const moveToWishlist = async (userId, productId) => {
    try {
        const response = await api.post(`/cart/${userId}/move-to-wishlist`, { productId });
        return response.data;
    } catch (error) {
        console.error('Error moving to wishlist:', error);
        throw error;
    }
};

// Move item from wishlist to cart
export const moveToCart = async (userId, productId) => {
    try {
        const response = await api.post(`/wishlist/${userId}/move-to-cart`, { productId });
        return response.data;
    } catch (error) {
        console.error('Error moving item to cart:', error);
        throw error;
    }
};

// Fetch product by ID
export const fetchProductById = async (productId) => {
    try {
        const response = await api.get(`/products/${productId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching product by ID:', error);
        throw error;
    }
};

export default api;
