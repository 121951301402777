import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { fetchProductById, addToCart, addToWishlist } from '../api/axios';

function ProductDetails({ cart, setCart, wishlist, setWishlist, userId }) {
    const { id } = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        const loadProduct = async () => {
            try {
                const productData = await fetchProductById(id);
                setProduct(productData);
            } catch (error) {
                console.error('Error loading product:', error);
            }
        };
        loadProduct();
    }, [id]);

    const handleAddToCart = async () => {
        try {
            await addToCart(userId, product._id);

            const updatedCart = [...cart];
            const existingProduct = updatedCart.find(item => item._id === product._id);

            if (existingProduct) {
                existingProduct.quantity += 1;
            } else {
                updatedCart.push({ ...product, quantity: 1 });
            }

            setCart(updatedCart);
        } catch (error) {
            console.error('Error adding to cart:', error);
        }
    };

    const handleAddToWishlist = async () => {
        try {
            const response = await addToWishlist(userId, product._id);
            setWishlist(response.products);
        } catch (error) {
            console.error('Error adding to wishlist:', error);
        }
    };

    if (!product) return <div>Loading...</div>;

    return (
        <div className="product-details">
            <h2>{product.name}</h2>
            <img src={product.imageUrl} alt={product.name} />
            <p>{product.description}</p>
            <p><strong>Price: ${product.price}</strong></p>
            <Button variant="primary" onClick={handleAddToCart}>Add to Cart</Button>
            <Button variant="secondary" onClick={handleAddToWishlist} style={{ marginLeft: '10px' }}>
                Add to Wishlist
            </Button>
        </div>
    );
}

export default ProductDetails;
