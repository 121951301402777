import React, { useEffect, useState, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { fetchCart, moveToWishlist, removeFromCart, fetchWishlist } from '../../api/axios';

function Cart({ cart, setCart, wishlist, setWishlist, userId }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to load the cart
    const loadCart = useCallback(async () => {
        try {
            setLoading(true);
            const cartData = await fetchCart(userId);

            // Filter out any null or undefined items
            const validCartItems = cartData.items.filter(item =>
                item !== null &&
                item !== undefined &&
                item.productId !== null &&
                item.productId !== undefined
            );
            setCart(validCartItems);
            setError(null);
        } catch (error) {
            console.error("Error loading cart:", error);
            setError("Failed to load cart. Please try again.");
        } finally {
            setLoading(false);
        }
    }, [userId, setCart]);

    // Function to load the wishlist
    const loadWishlist = useCallback(async () => {
        try {
            const wishlistData = await fetchWishlist(userId);
            setWishlist(wishlistData.products || []);
        } catch (error) {
            console.error("Error loading wishlist:", error);
        }
    }, [userId, setWishlist]);

    useEffect(() => {
        if (userId) {
            loadCart();
            loadWishlist();
        }
    }, [userId, loadCart, loadWishlist]);

    const handleMoveToWishlist = async (item) => {
        if (!item.productId || !item.productId._id) return;
        try {
            setLoading(true);
            setCart((prevCart) => prevCart.filter((cartItem) => cartItem._id !== item._id));
            await moveToWishlist(userId, item.productId._id);
            await Promise.all([loadCart(), loadWishlist()]);
            setError(null);
        } catch (error) {
            console.error('Error moving product to wishlist:', error);
            setError('Failed to move product to wishlist');
            await loadCart();
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveFromCart = async (item) => {
        if (!item.productId || !item.productId._id) return;
        try {
            await removeFromCart(userId, item.productId._id);
            setCart(cart.filter(cartItem => cartItem._id !== item._id));
            setError(null);
        } catch (error) {
            console.error("Error removing item from cart:", error);
            setError("Failed to remove item from cart. Please try again.");
        }
    };

    if (loading) {
        return <div>Loading your cart...</div>;
    }

    return (
        <div className="cart-container">
            <h2>Your Cart</h2>
            {error && (
                <div className="cart-error-message">
                    {error}
                </div>
            )}
            {cart.length === 0 ? (
                <div>Your cart is empty.</div>
            ) : (
                <div className="cart-items">
                    {cart.map(item => (
                        item && item.productId && item.productId._id && (
                            <div key={item._id} className="cart-item">
                                <div className="cart-item-image">
                                    <img src={item.productId.image} alt={item.name} />
                                </div>
                                <div className="cart-item-details">
                                    <h3>{item.productId.name}</h3>
                                    <p>Price: ${item.productId.price}</p>
                                    <p>Quantity: {item.quantity}</p>
                                </div>
                                <div className="cart-item-actions">
                                    <Button
                                        variant="secondary"
                                        onClick={() => handleMoveToWishlist(item)}
                                        className="mb-2"
                                    >
                                        Move to Wishlist
                                    </Button>
                                    <Button
                                        variant="danger"
                                        onClick={() => handleRemoveFromCart(item)}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            )}
        </div>
    );
}

export default Cart;