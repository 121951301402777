import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbars from './components/Navbar/navbar';
import ProductList from './components/ProductList';
import ProductDetails from './components/ProductDetails';
import Cart from './components/Navbar/Cart';
import Wishlist from './components/Navbar/Wishlist';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import { fetchProducts, fetchCart, fetchWishlist } from './api/axios';
import Profile from './components/Profile';

function App() {
  const [cart, setCart] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Get userId from localStorage if user is logged in
  const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).id : null;

  useEffect(() => {
    const loadUserData = async () => {
      try {
        setLoading(true);

        // First verify if the token is valid
        const token = localStorage.getItem('token');
        if (!token) {
          setProducts([]);
          setFilteredProducts([]);
          return;
        }

        // Fetch products first
        const productsData = await fetchProducts();
        setProducts(productsData);
        setFilteredProducts(productsData);

        // Then fetch user-specific data if logged in
        if (userId) {
          const [cartData, wishlistData] = await Promise.all([
            fetchCart(userId),
            fetchWishlist(userId)
          ]);

          setCart(cartData?.items || []);
          setWishlist(wishlistData?.products || []);
        }

        setError(null);
      } catch (error) {
        console.error('Error loading data:', error);
        if (error.response?.status === 401) {
          // Clear invalid token
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        setError('Failed to load data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadUserData();
  }, [userId]);

  const handleSearch = (searchQuery) => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = products.filter(product =>
      product.name.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredProducts(filtered);
  };

  return (
    <Router>
      <div className="App">
        <Navbars cart={cart} wishlist={wishlist} onSearch={handleSearch} />
        {error && (
          <div style={{ color: 'red', textAlign: 'center', padding: '10px' }}>
            {error}
          </div>
        )}
        <Routes>
          <Route path="/" element={<ProductList
            cart={cart}
            setCart={setCart}
            wishlist={wishlist}
            setWishlist={setWishlist}
            filteredProducts={filteredProducts}
            loading={loading}
            userId={userId}
          />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />

          <Route path="/product/:id" element={<ProductDetails
            cart={cart}
            setCart={setCart}
            wishlist={wishlist}
            setWishlist={setWishlist}
            userId={userId}
          />} />

          <Route path="/cart" element={
            <ProtectedRoute>
              <Cart
                cart={cart}
                setCart={setCart}
                wishlist={wishlist}
                setWishlist={setWishlist}
                userId={userId}
              />
            </ProtectedRoute>
          } />

          <Route path="/wishlist" element={
            <ProtectedRoute>
              <Wishlist
                cart={cart}
                setCart={setCart}
                wishlist={wishlist}
                setWishlist={setWishlist}
                userId={userId}
              />
            </ProtectedRoute>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;