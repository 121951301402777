import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    useEffect(() => {
        // Verify token is valid on mount
        if (!token || !user) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
        }
    }, [token, user]);

    if (!token || !user) {
        return <Navigate to="/login" replace />;
    }

    return children;
};

export default ProtectedRoute;