import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link, useNavigate } from 'react-router-dom';
import { FaShoppingCart, FaHeart, FaUser } from 'react-icons/fa';
import './navbar.css';

function Navbars({ cart, wishlist, onSearch }) {
    const navigate = useNavigate();
    const totalItemsInCart = cart.reduce((total, item) => total + item.quantity, 0);
    const totalItemsInWishlist = wishlist?.products?.length || wishlist?.length || 0;
    const [searchQuery, setSearchQuery] = useState('');

    // Check if user is logged in
    const user = JSON.parse(localStorage.getItem('user'));
    const isLoggedIn = !!localStorage.getItem('token');

    const handleSearch = (e) => {
        e.preventDefault();
        onSearch(searchQuery);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
    };

    return (
        <Navbar className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src="/Pearllogo.jpg"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    Serene Pearls
                </Navbar.Brand>

                <form onSubmit={handleSearch} className="search-container">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search for products..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button type="submit" className="search-button">Search</button>
                </form>

                <div className="nav-links-container">
                    <div className="cart-wishlist-container">
                        <Nav.Link as={Link} to="/cart" style={{ position: 'relative' }}>
                            <FaShoppingCart style={{ marginRight: '5px' }} /> Cart
                            {totalItemsInCart > 0 && (
                                <span className="cart-count-badge">{totalItemsInCart}</span>
                            )}
                        </Nav.Link>
                        {<Nav.Link as={Link} to="/wishlist" style={{ position: 'relative' }}>
                            <FaHeart style={{ marginRight: '5px' }} /> Wishlist
                            {totalItemsInWishlist > 0 && (
                                <span className="wishlist-count-badge">{totalItemsInWishlist}</span>
                            )}
                        </Nav.Link>}
                    </div>
                    <div className="auth-container">
                        {isLoggedIn ? (
                            <>
                                <Nav.Link as={Link} to="/profile">
                                    <FaUser style={{ marginRight: '5px' }} />
                                    {user?.name}
                                </Nav.Link>
                                <Nav.Link onClick={handleLogout}>
                                    Logout
                                </Nav.Link>
                            </>
                        ) : (
                            <>
                                <Nav.Link as={Link} to="/login">Login</Nav.Link>
                                <Nav.Link as={Link} to="/register">Register</Nav.Link>
                            </>
                        )}
                    </div>
                </div>
            </Container>
        </Navbar>
    );
}

export default Navbars;