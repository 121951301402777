import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import { fetchProducts, addToCart, addToWishlist } from '../api/axios';
import './productlist.css';

function ProductList({ userId, cart, setCart, wishlist, setWishlist }) {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadProducts = async () => {
            try {
                setLoading(true);
                const data = await fetchProducts();
                setProducts(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching products:', error);
                setError('Failed to load products. Please try again later.');
                setLoading(false);
            }
        };
        loadProducts();
    }, []);

    const handleAddToCart = async (product) => {
        try {
            await addToCart(userId, product._id);
            const updatedCart = [...cart];
            const existingProduct = updatedCart.find(item => item._id === product._id);

            if (existingProduct) {
                existingProduct.quantity += 1;
            } else {
                updatedCart.push({ ...product, quantity: 1 });
            }

            setCart(updatedCart);
        } catch (error) {
            console.error('Error adding to cart:', error);
            setError('Failed to add item to cart. Please try again.');
        }
    };

    const handleAddToWishlist = async (product) => {
        try {
            const response = await addToWishlist(userId, product._id);
            setWishlist(response.products);
        } catch (error) {
            console.error('Error adding to wishlist:', error);
            setError('Failed to add item to wishlist. Please try again.');
        }
    };

    if (loading) return <div>Loading products...</div>;

    if (error) return <div style={{ color: 'red' }}>{error}</div>;

    return (
        <div className="product-list">
            {products.map((product) => (
                <Card key={product._id} style={{ width: '18rem', margin: '10px' }}>
                    <Card.Img variant="top" src={product.image} alt={product.name} />
                    <Card.Body>
                        <Card.Title>{product.name}</Card.Title>
                        <Card.Text>${product.price}</Card.Text>
                        <Button
                            variant="primary"
                            onClick={() => navigate(`/product/${product._id}`)}
                        >
                            View Details
                        </Button>
                        <Button
                            variant="success"
                            onClick={() => handleAddToCart(product)}
                            style={{ marginTop: '10px' }}
                        >
                            Add to Cart
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => handleAddToWishlist(product)}
                            style={{ marginTop: '10px' }}
                        >
                            Add to Wishlist
                        </Button>
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
}

export default ProductList;
